import { Constants } from "../constants"

export interface IUsers {
    users: any;
    user: any;
    usersType: any;
    currentPage: number;
    totalUsers: number;
    itemsPerPage: number;
}
export enum AuthMode {
    UNKNOWN = "UNKNOWN",
    TOKEN_MODE = "TOKEN",
    COOKIES_MODE = "SESSION"
}
export interface IUser {
    authMode: AuthMode;
    token: string;
    logged: Boolean;
}
export interface IContract {
    userId: string
    contracts: any
    userContracts: any;
    contractAreas: any;
    contractService: any;
    userContractService: any;
}

export interface IClient {
    clients: any
}

export interface IGetAllUsersAction {
    type: typeof Constants.GET_ALL_USERS;
    payload: IUsers;
}
export interface IGetUserByIdAction {
    type: typeof Constants.GET_USER_BY_ID;
    payload: IUsers;
}
export interface IGetUsersTypeAction {
    type: typeof Constants.GET_USERS_TYPES;
    payload: IUsers;
}
export interface IGetAllContractsAction {
    type: typeof Constants.GET_ALL_CONTRACTS;
    payload: IContract;
}
export interface IGetUserContractsAction {
    type: typeof Constants.GET_USER_CONTRACTS;
    payload: IContract;
}
export interface IGetAreaByContractAction {
    type: typeof Constants.GET_CONTRACT_AREA;
    payload: IContract;
}
export interface IGetServiceByUserContractAction {
    type: typeof Constants.GET_USER_CONTRACT_SERVICE;
    payload: IContract;
}
export interface IGetServiceByContractAction {
    type: typeof Constants.GET_CONTRACT_SERVICE;
    payload: IContract;
}
//
//Clients
export interface IGetClients {
    type: typeof Constants.GET_ALL_CLIENTS;
    payload: IClient;
}
export interface ILoading {
    loading?: boolean,
    loadingContract?: boolean,
    loadingContractArea?: boolean
    loadingContractService?: boolean
}
export interface IError {
    message: string,
    status: number | null
}

// User Actions
export interface ILoginAction {
    type: typeof Constants.LOG_IN;
    payload: IUser;
}
export interface ILogoutAction {
    type: typeof Constants.LOG_OUT;
    payload: IUser;
}
export interface IErrorsAction {
    type: typeof Constants.ERROR;
    payload: IError;
}
export interface ILoadingAction {
    type: typeof Constants.DATA_LOADING;
    payload: ILoading;
}
export interface ILoadingContractAction {
    type: typeof Constants.LOADING_CONTRACT;
    payload: ILoading;
}
export interface ILoadingContractAreaAction {
    type: typeof Constants.LOADING_CONTRACT_AREA;
    payload: ILoading;
}
export interface ILoadingContractServiceAction {
    type: typeof Constants.LOADING_CONTRACT_SERVICE;
    payload: ILoading;
}
export type LoadingActionTypes = ILoadingAction | ILoadingContractAreaAction | ILoadingContractAction | ILoadingContractServiceAction
export type ErrorsActionTypes = IErrorsAction
export type ClientActionTypes = IGetClients
export type AuthActionTypes = ILoginAction | ILogoutAction
export type UserActionTypes = IGetAllUsersAction | IGetUsersTypeAction | IGetUserByIdAction;
export type ContractActionTypes = IGetAllContractsAction | IGetUserContractsAction | IGetAreaByContractAction | IGetServiceByContractAction | IGetServiceByUserContractAction;
export type AppActions = UserActionTypes | ContractActionTypes | ClientActionTypes | LoadingActionTypes | ErrorsActionTypes | AuthActionTypes;
