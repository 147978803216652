import { loadingSpinner } from ".";
import Request from "../services/Request";
import { Constants } from "../constants";
export const getClients = () => {
    return (dispatch: any) => {
        dispatch(loadingSpinner(true))
        return Request.get('/client', false).then((response: any) => {
            dispatch({
                type: Constants.GET_ALL_CLIENTS,
                payload: {
                    clients: response.data
                }
            })
        }).catch(() => {
        });
    }
}