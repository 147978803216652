import React, { useEffect } from "react";
import { connect } from "react-redux";
import UserContrats from "../../components/userContrats";
import { withRouter } from "react-router-dom";
import Loading from "../../components/elements/loading";
import { getContracts, getContractAreas, addUserContract, loadingSpinner, getUserContractService, getClients, getUserContract, getUserById, updateUserContract, deleteUserContract } from "../../actions";
import { getContractService, postUserContractService, putUserContractService, deleteUserContractService } from "../../actions/contract";


const UsersContractManager = (props: any) => {
    const addUserContract = (contract: string, areas: string[]) => {
        const value = {
            user: props.contracts.userId,
            contract: contract,
            contractAreas: areas
        }
        props.addUserContract(value)
    }
    const searchContracts = (query: string, clientId: string) => {
        props.getContracts(query, clientId)
    }
    useEffect(() => {
        if (props.logged) {
            const userId = props.match.params.id
            props.loadingSpinner(true)
            props.getUserById(userId)
            props.getUserContract(userId).then(() => {
                return props.getClients()
            }).then(() => {
                props.loadingSpinner(false)
            })
        }
    }, [props.logged])
    const getContractDetails = (contractId: string, userContractId: string) => {
        props.getContractAreas(contractId);
        props.getContractService(contractId).then(() => {
            props.getUserContractService(userContractId)
        })
    }
    const saveUserContract = (body: any, userContractServiceId?: string): Promise<any> => {
        if (!userContractServiceId && body.contractServices.length > 0) {
            return props.postUserContractService(body)
        }
        if (userContractServiceId && body.contractServices.length === 0) {
            return props.deleteUserContractService(userContractServiceId)
        }
        if (userContractServiceId && body.contractServices.length > 0) {
            return props.putUserContractService(body, userContractServiceId)
        }
        return new Promise<any>((resolve, reject) => {
            setTimeout(function () {
                resolve('')
            }, 2000);
        })
    }
    const deleteUserContract = (userContractId: string) => {
        props.deleteUserContract(userContractId).then(() => {
            return props.getUserContract(props.contracts.userId)
        }).then(() => {
            props.loadingSpinner(false)
        })
    }
    return (
        <>
            {props.loading.loading && <Loading></Loading>}
            <div className="container mt-5 h-75">
                <UserContrats
                    loading={props.loading}
                    currentUser={props.currentUser}
                    contractService={props.contracts.contractService}
                    userContractService={props.contracts.userContractService}
                    contractAreas={props.contracts.contractAreas}
                    userContracts={props.contracts.userContracts}
                    contracts={props.contracts.contracts}
                    clients={props.client.clients}
                    saveUserContract={saveUserContract}
                    searchContracts={searchContracts}
                    getArea={props.getContractAreas}
                    addUserContract={addUserContract}
                    getContractDetails={getContractDetails}
                    updateUserContract={props.updateUserContract}
                    deleteUserContract={deleteUserContract}
                ></UserContrats>
            </div>
        </>
    )
}
const mapStateToProps = (state: any) => ({
    loading: state.loading,
    contracts: state.contracts,
    client: state.client,
    logged: state.auth.logged,
    currentUser: state.users.user
})
const mapDispatchToProps = {
    getContracts,
    getContractAreas,
    addUserContract,
    loadingSpinner,
    getUserContractService,
    getContractService,
    getUserContract,
    getClients,
    postUserContractService,
    putUserContractService,
    getUserById,
    updateUserContract,
    deleteUserContractService,
    deleteUserContract
}
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(UsersContractManager))