import axios from "axios";
import { config } from "../config";
import { loadingSpinner, errorTrigged } from "../actions";
import { IError, AuthMode } from "../types";
import { store } from "../store";
class Request {
    public static cancel: any;
    private base: string;
    private basePath: string;
    private CancelToken: any;
    constructor() {
        this.base = config.apiUrl as string;
        this.basePath = config.basePath as string;
        this.CancelToken = axios.CancelToken;
    }
    getRequestParam = () => {
        if (config.authMode === AuthMode.COOKIES_MODE) {
            return { withCredentials: true }
        }
        return {}
    }
    public async post(endpoint: string, body: any) {
        const httpInstance = this.getHttpInstance();
        const param = this.getRequestParam()
        return httpInstance.post(endpoint, JSON.stringify(body), param)
    }
    public async put(endpoint: string, body: any) {
        const httpInstance = this.getHttpInstance();
        const param = this.getRequestParam()
        return httpInstance.put(endpoint, JSON.stringify(body), param)
    }
    public async delete(endpoint: string) {
        const httpInstance = this.getHttpInstance();
        const param = this.getRequestParam()
        return httpInstance.delete(endpoint, param)
    }
    public async get(endpoint: string, cancelOption: boolean) {
        Request.cancel && Request.cancel();
        const httpInstance = this.getHttpInstance();
        let param: any = this.getRequestParam()
        if (cancelOption) {
            param = {
                cancelToken: new this.CancelToken(function executor(c: any) {
                    Request.cancel = c
                })
            }
        }
        return httpInstance.get(endpoint, param)
    }
    public async refreshToken() {
        const body = JSON.stringify({
            refresh_token: sessionStorage.getItem("refreshToken"),
        });
        const endpoint = `/token/refresh`;
        const httpInstance = this.getHttpInstance();
        return httpInstance.post(endpoint, body);
    }

    private getHttpInstance() {
        const { base, basePath } = this;
        const state = store.getState()
        const header: { [key: string]: string } = {
            "Content-Type": "application/json;charset=utf-8",
        };
        if (state.auth.token) {
            header['Authorization'] = `Bearer ${localStorage.getItem("token-heimdall")}`;
        }
        const path = state.auth.logged ? basePath : ''
        const httpInstance = axios.create({
            baseURL: `${base}${path}`,
            headers: header,
        });

        httpInstance.interceptors.response.use(
            (response: any) => {
                return response
            },
            (error: { response: { status: any; data: { message: any; }; }; message: any; }) => {
                if (axios.isCancel(error)) {
                    return;
                }
                if (error.response) {
                    const {
                        status,
                        data: { message },
                    } = error.response;
                    const isPermessionError = 401 === status || 403 === status || 302 === status;

                    showError(
                        { status, message }
                    );
                    if (isPermessionError) {
                        localStorage.clear();
                        window.location = `${config.apiUrl}/saml/logout` as any;
                    }
                } else {
                    console.log('error :', error, `${base}  ${basePath}`)
                    showError({ status: 0, message: error.message });
                }

                return Promise.reject(error);
            },
        );
        return httpInstance;
    }
    public cancelRequest() {
        Request.cancel && Request.cancel()
    }
}
export const showError = (error: IError) => {
    store.dispatch(loadingSpinner(false))
    store.dispatch(errorTrigged(error))

}

export default new Request();