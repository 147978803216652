import React from "react";
import { Pagination } from "react-bootstrap";

interface IPagination {
    users: number,
    currentPage: number
    totalUsers: number,
    itemsPerPage: number,
    pageChange: Function
}

const SludePagination: React.SFC<IPagination> = (props) => {
    const page_count = Math.ceil(props.totalUsers / props.itemsPerPage)
    return (
        page_count > 1 ? < Pagination className='justify-content-center' >
            <Pagination.Prev
                disabled={props.currentPage === 1}
                onClick={() => props.pageChange(props.currentPage - 1)} />
            {
                Array.from({ length: page_count }, (x, i) => (
                    <Pagination.Item
                        active={props.currentPage === (i + 1)}
                        key={i}
                        onClick={() => props.pageChange(i + 1)}>
                        {i + 1}
                    </Pagination.Item>
                ))
            }
            <Pagination.Next
                disabled={props.currentPage === page_count}
                onClick={() => props.pageChange(props.currentPage + 1)} />
        </Pagination > :
            <></>
    )
}

export default SludePagination