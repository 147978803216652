
import { connectRouter } from "connected-react-router";
import { History } from "history";
import { combineReducers } from "redux";
import { usersReducers, authReducer } from "./user_reducer";
import { contractReducers } from './contract_reducer'
import loadingReducers from "./loading_reducer";
import errorReducers from "./error_reducer";
import { clientReducer } from "./client_reducer";

const rootReducer = (history: History) => combineReducers({
    router: connectRouter(history),
    users: usersReducers,
    loading: loadingReducers,
    error: errorReducers,
    contracts: contractReducers,
    client: clientReducer,
    auth: authReducer
});

export default rootReducer;