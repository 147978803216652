import qs from "qs";
import { Constants, AUTH_CODE } from "../constants";
import { store } from "../store";
import { AuthMode } from "../types";
import { config } from "../config";
import Request from "./Request";

export class AuthenticateService {
    public static login() {
        if (config.authMode === AuthMode.TOKEN_MODE) {
            const { token } = qs.parse(window.location.search.substring(1));
            if (!token) {
                window.location = config.apiUrl + "/saml/login" as any;
            } else {
                localStorage.setItem("token-sludge", token);
                store.dispatch({
                    payload: {
                        authMode: AuthMode.TOKEN_MODE,
                        token: token,
                        logged: true,
                    },
                    type: Constants.LOG_IN,
                });
            }
        }
    }

    public static checkLoggedIn() {
        Request.get('/saml/is-logged-in', false)
            .then(function (response: any) {
                store.dispatch({
                    payload: {
                        authMode: AuthMode.COOKIES_MODE,
                        token: '',
                        logged: response.data.code === AUTH_CODE.USER_AUTHENTICATED,
                    },
                    type: Constants.LOG_IN,
                });
            })
    }
}
