import { UserActionTypes, IUsers, IUser, AuthActionTypes, AuthMode } from "../types";
import { Constants } from "../constants";
const usersState: IUsers = {
    user: {
        type: '',
        username: '',
        firstName: '',
        lastName: '',
        suezGid: '',
        ldapDn: '',
        ldapUidNumber: '',
        civility: '',
        function: '',
        phone: '',
        mobilePhone: ''
    },
    users: [],
    usersType: [],
    currentPage: 1,
    totalUsers: 0,
    itemsPerPage: 15
};
export const usersReducers = (state = usersState, action: UserActionTypes) => {
    switch (action.type) {
        case Constants.GET_ALL_USERS:
            return {
                ...state,
                users: action.payload.users,
                totalUsers: action.payload.totalUsers,
                currentPage: action.payload.currentPage,
                itemsPerPage: action.payload.itemsPerPage
            }
        case Constants.GET_USERS_TYPES:
            return {
                ...state,
                usersType: action.payload.usersType
            }
        case Constants.GET_USER_BY_ID:
            return {
                ...state,
                user: action.payload.user
            }
        default:
            return state;

    }
}
const user: IUser = {
    authMode: AuthMode.UNKNOWN,
    token: '',
    logged: false
}
export const authReducer = (state = user, action: AuthActionTypes) => {
    switch (action.type) {
        case Constants.LOG_IN:
            return { ...state, token: action.payload.token, logged: action.payload.logged, authMode: action.payload.authMode };
        case Constants.LOG_OUT:
            return { ...state, token: action.payload.token, logged: action.payload.logged };
        default:
            return state;
    }
}
