import Request from "../services/Request";
import { Constants } from "../constants";

export const getContracts = (query: string, clientId: string) => {
    return (dispatch: any) => {
        dispatch({
            type: Constants.LOADING_CONTRACT,
            payload: {
                loadingContract: true
            }
        })
        dispatch({
            type: Constants.GET_CONTRACT_AREA,
            payload: {
                contractAreas: []
            }
        })
        return Request.get('/contract?' +
            (query !== '' ? 'q=' + query : '') +
            (clientId !== '' ? '&client=' + clientId : ''), false)
            .then((response: any) => {
                dispatch({
                    type: Constants.GET_ALL_CONTRACTS,
                    payload: {
                        contracts: response.data
                    }
                })
                dispatch({
                    type: Constants.LOADING_CONTRACT,
                    payload: {
                        loadingContract: false
                    }
                })
            }).catch(() => {
            });
    }
}

export const getContractAreas = (contractId: string) => {
    return (dispatch: any) => {
        dispatch({
            type: Constants.LOADING_CONTRACT_AREA,
            payload: {
                loadingContractArea: true
            }
        })
        return Request.get('/contract/' + contractId + '/contractArea', false)
            .then((response: any) => {
                dispatch({
                    type: Constants.GET_CONTRACT_AREA,
                    payload: {
                        contractAreas: response.data
                    }
                })
                dispatch({
                    type: Constants.LOADING_CONTRACT_AREA,
                    payload: {
                        loadingContractArea: false
                    }
                })
            }).catch(() => {
            });
    }
}
export const getUserContractService = (userContractId: string) => {
    return (dispatch: any) => {
        dispatch({
            type: Constants.LOADING_CONTRACT_SERVICE,
            payload: {
                loadingContractService: true
            }
        })
        return Request.get('/userContract/' + userContractId + '/userContractService', false)
            .then((response: any) => {
                dispatch({
                    type: Constants.GET_USER_CONTRACT_SERVICE,
                    payload: {
                        userContractService: response.data
                    }
                })
                dispatch({
                    type: Constants.LOADING_CONTRACT_SERVICE,
                    payload: {
                        loadingContractService: false
                    }
                })
            }).catch(() => {
            });
    }
}
export const getContractService = (contractId: string) => {
    return (dispatch: any) => {
        dispatch({
            type: Constants.LOADING_CONTRACT_SERVICE,
            payload: {
                loadingContractService: true
            }
        })
        return Request.get('/contract/' + contractId + '/contractService', false)
            .then((response: any) => {
                dispatch({
                    type: Constants.GET_CONTRACT_SERVICE,
                    payload: {
                        contractService: response.data
                    }
                })
            }).catch(() => {
            });
    }
}
export const postUserContractService = (body: any) => {
    return (dispatch: any) => {
        return Request.post('/userContractService', body)
            .catch(() => {
            });
    }
}
export const putUserContractService = (body: any, userContractServiceId: string) => {
    return (dispatch: any) => {
        return Request.put('/userContractService/' + userContractServiceId, body)
            .catch(() => {
            });
    }
}
export const deleteUserContractService = (userContractServiceId: string) => {
    return (dispatch: any) => {
        return Request.delete('/userContractService/' + userContractServiceId)
            .catch(() => {
            });
    }
}