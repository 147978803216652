import { Constants } from "../constants";
import { AppActions, IError } from "../types";

export const loadingSpinner = (value: boolean): AppActions => {
    return {
        type: Constants.DATA_LOADING,
        payload: {
            loading: value
        }
    }
}

export const errorTrigged = (error: IError) => {
    return {
        payload: {
            message: error.message,
            status: error.status
        },
        type: Constants.ERROR
    }
}