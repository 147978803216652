import { IContract, ContractActionTypes } from "../types";
import { Constants } from "../constants";
const contractState: IContract = {
    userId: '',
    contracts: [],
    userContracts: [],
    contractAreas: [],
    contractService: [],
    userContractService: []
};
export const contractReducers = (state = contractState, action: ContractActionTypes) => {
    switch (action.type) {
        case Constants.GET_ALL_CONTRACTS:
            return { ...state, contracts: action.payload.contracts }
        case Constants.GET_USER_CONTRACTS:
            return { ...state, userContracts: action.payload.userContracts, userId: action.payload.userId }
        case Constants.GET_CONTRACT_AREA:
            return { ...state, contractAreas: action.payload.contractAreas }
        case Constants.GET_USER_CONTRACT_SERVICE:
            return { ...state, userContractService: action.payload.userContractService }
        case Constants.GET_CONTRACT_SERVICE:
            return { ...state, contractService: action.payload.contractService }
        default:
            return state;

    }
}