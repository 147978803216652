import "./users.scss";
import React, { useState } from "react";
import Search from "../elements/Search";
import { SzTable, SzButton, SzModal } from "react-theme-components";
import SludgePagination from "../elements/pagination";
import { useHistory } from "react-router-dom";
import DeleteModalBody from "../elements/deleteModalBody";


interface IUsers {
    users: any,
    currentPage: number,
    totalUsers: number,
    itemsPerPage: number,
    pageChange: Function,
    filterUsers: Function,
    updateUser: Function,
    deleteUser: Function
}


const Users: React.SFC<IUsers> = (props) => {
    const history = useHistory();
    const defaultUser = {
        id: '',
        firstName: '',
        lastName: ''
    }
    const [deleteModalData, setDeleteModalData] = useState({
        show: false,
        user: defaultUser
    });
    const columns = [
        {
            dataField: 'username',
            text: "Nom de l'utilisateur",
        }, {
            dataField: 'lastName',
            text: "Nom ",
        }, {
            dataField: 'firstName',
            text: "Prénom",
        }, {
            dataField: 'id',
            text: "",
            formatter: actionFormatter
        }
    ]
    const renderModalBody = () => {
        const user = deleteModalData.user
        return (
            <DeleteModalBody
                title={`Voulez vous vraiment supprimer l'utilisateur ${user.lastName} ${user.firstName}?`}
                onClickCancel={() => {
                    setDeleteModalData({ show: false, user: defaultUser })
                }}
                onClickDelete={() => {
                    props.deleteUser(user.id)
                    setDeleteModalData({ show: false, user: defaultUser })
                }}
            ></DeleteModalBody >
        )
    }
    return (
        <div className='user'>
            <div className="row pb-3">
                <div className="col">
                    <h1 className="title">
                        Heimdall : gestion des accès
                    </h1>
                </div>
            </div>
            <div className="row align-items-center">
                <div className="col">
                    <Search placeholder="Chercher parmi les utilisateurs"
                        search={props.filterUsers}
                    ></Search>
                </div>
                <div className="col-auto">
                    <SzButton
                        className=''
                        onClick={() => {
                            history.push('utilisateurs/ajouter')
                        }}>
                        Nouveau utilisateur
                    </SzButton>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col h-100 w-100 overflow-auto">
                    <SzTable
                        data={props.users}
                        keyField={'tableUser'}
                        columns={columns}
                    />
                </div>
            </div>
            <SludgePagination
                currentPage={props.currentPage}
                totalUsers={props.totalUsers}
                users={props.users}
                pageChange={props.pageChange}
                itemsPerPage={props.itemsPerPage}>
            </SludgePagination>
            <SzModal
                title={"Suppression d'utilisateur"}
                show={deleteModalData.show}
                handleClose={() => setDeleteModalData({ show: false, user: defaultUser })}
                size={'lg'}
                className="danger"
            >
                {renderModalBody()}
            </SzModal>
        </div>
    )
    function actionFormatter(cell: any, row: any, rowIndex: any, formatExtraData: any) {
        const user = row;
        return (
            <div className="row">
                <div className="col-auto cell-clickable info">
                    <a
                        href="#"
                        onClick={() => {
                            props.updateUser(user)
                            history.push('/utilisateurs/modifier/' + user['id'])
                        }}>
                        <i className="mdi mdi-pencil"></i>
                        Modifier
                    </a>
                </div>
                <div className="col-auto cell-clickable info">
                    <a
                        href="#"
                        onClick={() => {
                            history.push(`/utilisateurs/${user['id']}/contrats`)
                        }}>
                        <i className="mdi mdi-account-remove"></i>
                        Contrats
                    </a>
                </div>
                <div className="col-auto cell-clickable danger">
                    <a
                        href="#"
                        onClick={() => setDeleteModalData({ show: true, user: user })}>
                        <i className="mdi mdi-account-remove"></i>
                        Supprimer
                    </a>
                </div>
            </div>
        );
    }
}

export default Users;