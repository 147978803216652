import { SzTypographie } from "react-theme-components";
import React from "react";
import { Link } from "react-router-dom";
interface IGoTo {
    title: string,
    link: string
}
const GoTo = (props: IGoTo) => {
    return (
        <div className="row justify-content-end">
            <div className="col-auto">
                <SzTypographie className="m-0 btn btn-link" type="link-underline">
                    <Link to={props.link}>{props.title}</Link>
                </SzTypographie>
            </div>
        </div>
    )
}
export default GoTo