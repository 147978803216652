import React, { useState } from "react";
import { SzIcon, SzButton } from 'react-theme-components';
import "./Search.scss";

interface ISuggestion {
    farms: {
        id: string;
        description: string
    }[],
    cities: {
        code: string;
        description: string
    }[],

}
interface ISearch {
    search: Function
    placeholder: string,
}
function Search(props: ISearch) {
    const [searchTerm, setSearchTerm] = useState('')
    return (
        <> <div>
            <div className="input-group">
                <input value={searchTerm} type="text" className="search-input form-control"
                    placeholder={props.placeholder} aria-describedby="button-addon4"
                    onChange={(event) => setSearchTerm(event.target.value)} />
                <div className="input-group-append" id="button-addon4">
                    <SzButton className="btn btn-close" type="button" onClick={() => {
                        setSearchTerm('')
                        props.search('')
                    }}>
                        <span>
                            <SzIcon small icon="remove" variant="line" />
                        </span>
                    </SzButton>
                    <SzButton className="mt-0"
                        outline
                        type="submit"
                        onClick={() => props.search(searchTerm)}>
                        <span>
                            <SzIcon small icon="search" variant="line" />
                        </span>
                    </SzButton>

                </div>


            </div>
        </div>
        </>
    );
}

export default Search;
