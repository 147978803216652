import { ClientActionTypes, IClient } from "../types";
import { Constants } from "../constants";
const clientState: IClient = {
    clients: [],
};
export const clientReducer = (state = clientState, action: ClientActionTypes) => {
    switch (action.type) {
        case Constants.GET_ALL_CLIENTS:
            return { ...state, clients: action.payload.clients }
        default:
            return state;

    }
}