import React, { useState } from 'react'
import { Modal } from 'react-bootstrap';
import Search from '../Search';
import './contractPopup.scss';
import { SzCheckbox, SzSpinner, SzButton, SzSelect } from 'react-theme-components';
import { ILoading } from '../../../types';
interface IContractPopUp {
    loading: ILoading
    clients: any;
    contracts: any;
    contractAreas: any;
    show: boolean;
    handleClose: () => void;
    addUserContract: (contractId: string, areas: (string)[]) => void;
    searchContracts: (query: string, clientId: string) => void;
    getArea: (contractId: string) => void
}
interface IState {
    areas: string[];
}
const ContractPopup = (props: IContractPopUp) => {
    const [clientId, setClientId] = useState<any>(null)
    const [selectedContract, setselectedContract] = useState('')
    const [checkedArea, setcheckedArea] = useState<IState>({
        areas: []
    })
    const handleCheck = (id: string, checked: boolean) => {
        let tmp = checkedArea.areas
        if (checked) {
            tmp.push(id)
            setcheckedArea({ areas: tmp })
        } else {
            tmp.splice(tmp.indexOf(id), 1);
            setcheckedArea({ areas: tmp })
        }
    }
    return (
        <Modal dialogClassName=" h-100 contract-modal" size="lg" show={props.show} onHide={props.handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Ajouter un contrat à un utilisateur </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row justify-content-center">
                    <div className="col-6">
                        <SzSelect
                            className="mb-3"
                            id="id-2"
                            isClearable
                            isSearchable
                            name="input-2"
                            onChange={(item: any) => {
                                setClientId(item)
                            }}
                            options={props.clients.map((client: any) => { return { label: client.label, value: client.id } })}
                            placeholder="placeholder"
                            value={clientId}
                        />
                    </div>
                    <div className="col-6">
                        <Search placeholder='contrats' search={(searchTerm: string) => {
                            setselectedContract('')
                            setcheckedArea({ areas: [] })
                            props.searchContracts(searchTerm, clientId ? clientId.value : '')
                        }
                        }></Search>
                    </div>
                </div>
                <div className=" contracts-content row pt-3 pl-2">
                    <div className="col">
                        <div className="row ">
                            <div className="col-auto">
                                <label className='sub-title'> liste des contrats:</label>
                            </div>
                        </div>
                        {
                            props.loading.loadingContract ?
                                <div className="row justify-content-center">
                                    <div className="col-auto">
                                        <SzSpinner variant="primary" />
                                    </div>
                                </div>
                                :
                                props.contracts.length === 0 ?
                                    <div className="row justify-content-center">
                                        <div className="col-auto">
                                            <label> aucun  contrat trouvé</label>
                                        </div>
                                    </div>
                                    :
                                    props.contracts.map((contract: any) => (
                                        <SzButton
                                            className={contract.id === selectedContract ? 'mb-1 selectedContract' : 'mb-1'}
                                            key={contract.id}
                                            onClick={() => {
                                                setcheckedArea({ areas: [] })
                                                props.getArea(contract.id)
                                                setselectedContract(contract.id)
                                            }}
                                        >{contract.label}</SzButton>
                                    ))
                        }
                    </div>
                    <div className="col">
                        <div className="row ">
                            <div className="col-auto">
                                <label className='sub-title'> liste des emplacements:</label>
                            </div>
                        </div>
                        {
                            props.loading.loadingContractArea ?
                                <div className="row justify-content-center">
                                    <div className="col-auto">
                                        <SzSpinner variant="primary" />
                                    </div>
                                </div> :
                                props.contracts.length > 0 && props.contractAreas.length === 0 ?
                                    selectedContract === '' ?
                                        <div className="row justify-content-center">
                                            <div className="col-auto">
                                                <label> aucun contrat séléctionné</label>
                                            </div>
                                        </div>
                                        :
                                        <div className="row justify-content-center">
                                            <div className="col-auto">
                                                <label> pas d'emplacement pour le contrat séléctionné</label>
                                            </div>
                                        </div> :
                                    props.contractAreas.map((contractArea: any) => (
                                        <SzCheckbox
                                            key={contractArea.id}
                                            label={contractArea.area.label}
                                            checked={checkedArea.areas.includes(contractArea.id)}
                                            onChange={(event: any) => handleCheck(contractArea.id, event.target.checked)}
                                            type="checkbox"
                                        />
                                    ))
                        }
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <SzButton onClick={() => {
                    setClientId('')
                    setselectedContract('')
                    setcheckedArea({ areas: [] })
                    props.handleClose()
                    props.addUserContract(selectedContract, checkedArea.areas)
                }}>
                    Ajouter
          </SzButton>
            </Modal.Footer>
        </Modal >
    )
}
export default ContractPopup