import { Constants } from "../constants";
import { ILoading, LoadingActionTypes } from "../types";

const initialState: ILoading = {
    loading: false,
    loadingContract: false,
    loadingContractArea: false,
    loadingContractService: false,
};
const loadingReducers = (state = initialState, action: LoadingActionTypes): ILoading => {
    switch (action.type) {
        case Constants.DATA_LOADING:
            return { ...state, loading: action.payload.loading }
        case Constants.LOADING_CONTRACT:
            return { ...state, loadingContract: action.payload.loadingContract }
        case Constants.LOADING_CONTRACT_AREA:
            return { ...state, loadingContractArea: action.payload.loadingContractArea }
        case Constants.LOADING_CONTRACT_SERVICE:
            return { ...state, loadingContractService: action.payload.loadingContractService }
        default:
            return state
    }
}
export default loadingReducers